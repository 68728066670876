<template>
  <el-dialog
    append-to-body
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="提示"
    :visible.sync="show"
    width="600px"
  >
    <p style="margin-bottom: 10px">
      下架成功 <span style="color: green">{{ successNum || 0 }}</span
      >件，下架失败
      <span style="color: red">{{ errorList.length || 0 }}</span> 件
    </p>

    <el-table :data="errorList" border height="400px" style="width: 100%">
      <el-table-column
        show-overflow-tooltip
        prop="goodsName"
        label="商品名称"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="supplierName"
        label="供应商名称"
      ></el-table-column>

      <el-table-column
        show-overflow-tooltip
        prop="errMsg"
        label="失败原因"
      ></el-table-column>
    </el-table>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  props: ['errorList', 'successNum'],
  data() {
    return {
      tableData: []
    }
  }
}
</script>
<style lang="scss" scoped></style>
