<template>
  <page>
    <template slot="headerLeft">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="供应商" prop="goodsSupplier">
          <el-input placeholder="供应商" v-model="params.goodsSupplier" />
        </el-form-item>

        <el-form-item label="商品名称" prop="goodsName">
          <el-input placeholder="商品名称" v-model="params.goodsName" />
        </el-form-item>

        <el-form-item label="商品一级分类" prop="clazzId1">
          <el-select v-model="params.clazzId1" clearable>
            <el-option
              v-for="item in typeList1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </template>

    <template slot="headerInfo">
      <el-button
        v-auth="'middleman:batchUp'"
        :disabled="!selections.length"
        type="primary"
        @click="uploadToStoreBatch()"
        >批量下架</el-button
      >
    </template>

    <el-table
      v-loading="loading"
      :data="tableData"
      border
      @selection-change="selectHandler"
      height="100%"
      style="width: 100%"
    >
      <el-table-column
        show-overflow-tooltip
        type="selection"
        width="50"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="goodsName"
        label="商品名称"
      ></el-table-column>

      <el-table-column label="商品图片">
        <template #default="{ row }">
          <el-popover
            v-if="row.goodsImg"
            effect="light"
            trigger="hover"
            placement="top"
            width="320px"
          >
            <template #default>
              <el-image
                style="width: 300px; height: 300px"
                :src="row.goodsImg"
                fit="scale-down"
              ></el-image>
            </template>
            <template #reference>
              <el-image
                style="height: 50px"
                :src="row.goodsImg"
                fit="scale-down"
                :preview-src-list="[row.goodsImg]"
              ></el-image>
            </template>
          </el-popover>

          <el-image
            v-else
            :src="row.goodsImg"
            class="table-image"
            style="width: 50px; height: 50px"
          >
            <div slot="error" class="image-slot">
              <i
                style="font-size: 50px !important"
                class="el-icon-picture-outline"
              ></i>
            </div>
          </el-image>
        </template>
      </el-table-column>

      <el-table-column prop="specification" label="采购规格"></el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="unit"
        label="采购单位"
      ></el-table-column>
      <el-table-column show-overflow-tooltip label="供应单价">
        <template slot-scope="{ row }">
          <span>￥{{ row.supplyPrice }}</span>
        </template>
      </el-table-column>

      <el-table-column min-width="100" show-overflow-tooltip label="门店采购价">
        <template slot-scope="{ row }">
          <span>￥{{ row.purchasePrice || 0 }}</span>
        </template>
      </el-table-column>

      <el-table-column
        show-overflow-tooltip
        prop="clazzName1"
        label="商品一级分类"
        min-width="100"
      ></el-table-column>

      <el-table-column
        show-overflow-tooltip
        prop="brandName"
        label="品牌"
      ></el-table-column>

      <el-table-column
        show-overflow-tooltip
        prop="goodsSupplier"
        label="供应商"
      ></el-table-column>

      <el-table-column show-overflow-tooltip label="状态">
        <template>
          <span style="color: green">已上架</span>
        </template>
      </el-table-column>

      <el-table-column width="200" label="操作">
        <template slot-scope="{ row }">
          <el-button
            v-auth="'middleman:updateState'"
            @click="uploadToStore(row.barNo, row.supplierId)"
            type="text"
            >下架</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />

    <ErrorModal
      v-if="errorVisible"
      :visible.sync="errorVisible"
      :errorList="errorList"
      :successNum="successNum"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import ErrorModal from './components/ErrorModal'

export default {
  mixins: [watchParamsGetData],
  components: {
    ErrorModal
  },
  data() {
    return {
      loading: false,
      addToVisible: false,
      errorVisible: false,
      currentItem: null,
      selections: [],
      errorList: [],
      tableData: [],
      typeList1: [],
      detailData: null,
      dataForm: null,
      successNum: 0,
      params: {
        goodsSupplier: '',
        clazzId1: '',
        goodsName: '',
        barNo: '',
        limit: 20,
        page: 1
      },
      totalCount: 0
    }
  },
  created() {
    this.getTypeList()
    this.getData()
  },
  methods: {
    selectHandler(val) {
      this.selections = val.map(item => ({
        barNo: item.barNo,
        goodsName: item.goodsName,
        supplierName: item.goodsSupplier,
        supplierId: item.supplierId,
        state: item.state == 1 ? 3 : 1
      }))
    },

    adjustPrice(row) {
      this.currentItem = row
      this.addToVisible = true
    },

    async uploadToStoreBatch() {
      try {
        await this.$confirm(`是否要下架所选商品？`, '提示', {
          type: 'warning',
          confirmButtonText: '确认',
          cancelButtonText: '取消'
        })

        try {
          this.loading = true

          const res =
            await this.$api.purchaseGoodsMaintenance.uploadToStoreBatch({
              state: 3,
              goodsList: this.selections.map(item => ({
                barNo: item.barNo,
                supplierId: item.supplierId
              }))
            })

          if (!res.errorList) {
            this.$message.success('下架成功！')
          } else {
            const cloneArr = JSON.parse(JSON.stringify(this.selections))
            this.errorVisible = true
            this.errorList = res.errorList
            this.successNum = cloneArr.length - res.errorList.length
          }
          this.getData(true)
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      } catch (err) {}
    },

    async uploadToStore(barNo, supplierId) {
      try {
        await this.$confirm(`是否要下架该商品？`, '提示', {
          type: 'warning',
          confirmButtonText: '确认',
          cancelButtonText: '取消'
        })

        try {
          this.loading = true
          await this.$api.purchaseGoodsMaintenance.uploadToStore({
            barNo,
            supplierId,
            state: 3
          })
          this.$message.success(`下架成功！`)
          this.getData(true)
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      } catch (err) {}
    },

    async getTypeList() {
      try {
        const res = await this.$api.classification.getClazzList({
          limit: 1000,
          page: 1
        })
        this.typeList1 = (res.clazzList.list || []).map(item => {
          return {
            label: item.clazzName,
            value: item.id
          }
        })
      } catch (err) {}
    },
    async getData(query) {
      this.loading = true

      const sendData = JSON.parse(JSON.stringify(this.params))

      if (query) {
        sendData.page = 1
        sendData.limit = 20
      }

      sendData.currentPage = sendData.page
      sendData.pageSize = sendData.limit

      delete sendData.limit
      delete sendData.page
      sendData.state = 1
      try {
        const r = await this.$api.purchaseGoodsMaintenance.getList(sendData)

        const tableData = r.page.list.map(item => {
          item.goodsImg = this.$utils.getImg(item.goodsImg)

          item.supplyPrice = item.supplyPrice || 0

          return item
        })
        this.tableData = tableData
        this.totalCount = r.page.total
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
