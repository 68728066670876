var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',[_c('template',{slot:"headerLeft"},[_c('el-form',{ref:"filtersForm",attrs:{"inline":"","model":_vm.params}},[_c('el-form-item',{attrs:{"label":"供应商","prop":"goodsSupplier"}},[_c('el-input',{attrs:{"placeholder":"供应商"},model:{value:(_vm.params.goodsSupplier),callback:function ($$v) {_vm.$set(_vm.params, "goodsSupplier", $$v)},expression:"params.goodsSupplier"}})],1),_c('el-form-item',{attrs:{"label":"商品名称","prop":"goodsName"}},[_c('el-input',{attrs:{"placeholder":"商品名称"},model:{value:(_vm.params.goodsName),callback:function ($$v) {_vm.$set(_vm.params, "goodsName", $$v)},expression:"params.goodsName"}})],1),_c('el-form-item',{attrs:{"label":"商品一级分类","prop":"clazzId1"}},[_c('el-select',{attrs:{"clearable":""},model:{value:(_vm.params.clazzId1),callback:function ($$v) {_vm.$set(_vm.params, "clazzId1", $$v)},expression:"params.clazzId1"}},_vm._l((_vm.typeList1),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1)],1),_c('template',{slot:"headerInfo"},[_c('el-button',{directives:[{name:"auth",rawName:"v-auth",value:('middleman:batchUp'),expression:"'middleman:batchUp'"}],attrs:{"disabled":!_vm.selections.length,"type":"primary"},on:{"click":function($event){return _vm.uploadToStoreBatch()}}},[_vm._v("批量下架")])],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","height":"100%"},on:{"selection-change":_vm.selectHandler}},[_c('el-table-column',{attrs:{"show-overflow-tooltip":"","type":"selection","width":"50"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"goodsName","label":"商品名称"}}),_c('el-table-column',{attrs:{"label":"商品图片"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.goodsImg)?_c('el-popover',{attrs:{"effect":"light","trigger":"hover","placement":"top","width":"320px"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('el-image',{staticStyle:{"width":"300px","height":"300px"},attrs:{"src":row.goodsImg,"fit":"scale-down"}})]},proxy:true},{key:"reference",fn:function(){return [_c('el-image',{staticStyle:{"height":"50px"},attrs:{"src":row.goodsImg,"fit":"scale-down","preview-src-list":[row.goodsImg]}})]},proxy:true}],null,true)}):_c('el-image',{staticClass:"table-image",staticStyle:{"width":"50px","height":"50px"},attrs:{"src":row.goodsImg}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"error"},slot:"error"},[_c('i',{staticClass:"el-icon-picture-outline",staticStyle:{"font-size":"50px !important"}})])])]}}])}),_c('el-table-column',{attrs:{"prop":"specification","label":"采购规格"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"unit","label":"采购单位"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","label":"供应单价"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v("￥"+_vm._s(row.supplyPrice))])]}}])}),_c('el-table-column',{attrs:{"min-width":"100","show-overflow-tooltip":"","label":"门店采购价"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v("￥"+_vm._s(row.purchasePrice || 0))])]}}])}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"clazzName1","label":"商品一级分类","min-width":"100"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"brandName","label":"品牌"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"goodsSupplier","label":"供应商"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","label":"状态"}},[[_c('span',{staticStyle:{"color":"green"}},[_vm._v("已上架")])]],2),_c('el-table-column',{attrs:{"width":"200","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{directives:[{name:"auth",rawName:"v-auth",value:('middleman:updateState'),expression:"'middleman:updateState'"}],attrs:{"type":"text"},on:{"click":function($event){return _vm.uploadToStore(row.barNo, row.supplierId)}}},[_vm._v("下架")])]}}])})],1),_c('pagination',{attrs:{"slot":"pagination","totalCount":_vm.totalCount,"page-size":_vm.params.limit,"page":_vm.params.page},on:{"update:pageSize":function($event){return _vm.$set(_vm.params, "limit", $event)},"update:page-size":function($event){return _vm.$set(_vm.params, "limit", $event)},"update:page":function($event){return _vm.$set(_vm.params, "page", $event)},"change":_vm.getData},slot:"pagination"}),(_vm.errorVisible)?_c('ErrorModal',{attrs:{"visible":_vm.errorVisible,"errorList":_vm.errorList,"successNum":_vm.successNum},on:{"update:visible":function($event){_vm.errorVisible=$event}}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }